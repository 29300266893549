.error-404 {
  padding: 50px 20px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--accent-yellow);
  &-img {
    max-width: 936px;
    margin-bottom: 103px;
    @media (max-width: 1420px) {
      max-width: 580px; }
    @media (max-width: 767px) {
      margin-bottom: 50px; } }
  &-title {
    font-weight: 700;
    font-size: 45px;
    line-height: 58px;
    text-transform: uppercase;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      font-size: 30px;
      margin-bottom: 20px; } }
  &-desc {
    line-height: 24px;
    text-align: center;
    max-width: 452px;
    font-weight: 400;
    font-size: 16px;
    @media (max-width: 767px) {
      font-size: 14px; } }
  &-btn {
    width: 242px;
    height: 56px;
    border-radius: 2px;
    background: #FFFFFF;
    box-shadow: 0px 2px 5px 2px rgba(254, 137, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    transition: var(--trans-2s);
    &:hover {
      opacity: .8; }

    @media (max-width: 767px) {
      width: 100%; } } }
