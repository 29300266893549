section.contacts {
  display: block;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1420px) {
    padding: 50px 0; }
  &::before {
    @include ba();
    width: 60%;
    height: 350px;
    background: center / cover no-repeat url('../img/pattern-bw.svg');
    right: 0;
    top: 0;
    z-index: -1; }

  h4 {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    text-transform: uppercase;
    @media (max-width: 1220px) {
      font-size: 20px; }
    @media (max-width: 767px) {
      font-size: 16px; } }

  .constacts-top {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;

    .constacts-socials {
      padding: 70px 50px;
      width: 25%;
      color: #fff;
      background: var(--accent-yellow);
      box-shadow: 0px 3.03633px 6.07266px rgba(0, 0, 0, 0.25);
      @media (max-width: 1420px) {
        padding: 30px 20px; }
      @media (max-width: 1000px) {
        width: 100%;
        order: 1;
        margin-top: 60px; }

      h4 {
        text-align: center;
        margin-bottom: 42px; }

      &__list {
        &-item {
          padding: 15px 20px;
          margin-bottom: 25px;
          border-radius: 3px;
          box-sizing: border-box;
          border: 2px solid #FFFFFF;
          color: #fff;
          display: flex;
          align-items: center;
          font-size: 18px;
          transition: var(--trans-2s);
          &:hover {
            transform: translateX(10px); }

          &::before {
            content: '';
            margin-right: 18px; }
          &:nth-child(1) {
            &::before {
              width: 44px;
              height: 30px;
              background: center / contain no-repeat url('../img/ytb-w.svg'); } }
          &:nth-child(2) {
            margin-bottom: 0;
            &::before {
              width: 30px;
              height: 30px;
              background: center / contain no-repeat url('../img/inst-w.svg'); } } } } }

    .contacts-info {
      padding: 70px 50px;
      width: 70%;
      color: #fff;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background: var(--accent-yellow);
      box-shadow: 0px 3.03633px 6.07266px rgba(0, 0, 0, 0.25);
      @media (max-width: 1420px) {
        padding: 30px 20px; }
      @media (max-width: 1000px) {
        width: 100%;
        padding: 30px 10px 0; }

      h4 {
        margin-bottom: 30px; }

      &__item {
        // width: 48%
        flex-grow: 1;
        padding: 0 5px;
        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 32px; }

        &:nth-child(1) {
          ul li:nth-child(1) {
            &::before {
              background-image: url('../img/map.svg'); } }
          ul li:nth-child(2) {
            &::before {
              background-image: url('../img/phone.svg'); } }
          ul li:nth-child(3) {
            &::before {
              background-image: url('../img/mail.svg'); } } }

        &:nth-child(2) {
          ul li::before {
            background-image: url('../img/map.svg'); } }

        &:nth-child(3) {
          ul li:nth-child(1)::before {
            background-image: url('../img/tlgrm.svg'); }
          ul li:nth-child(2)::before {
            background-image: url('../img/whtsp.svg'); }
          ul li:nth-child(3)::before {
            background-image: url('../img/vbr.svg'); } } }

      ul li {
        margin-bottom: 20px;
        color: var(--text-color);
        padding-left: 25px;
        position: relative;
        &::before {
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          background: center / contain no-repeat;
          position: absolute;
          left: 0;
          top: 0; }

        a {
          text-transform: uppercase;
          transition: var(--trans-2s);
          &:hover {
            opacity: .8; } } } } }

  .contacts-form {
    margin-top: 150px;
    padding: 64px 50px;
    color: #fff;
    border-radius: 2px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    background: center / cover no-repeat url('../img/cf-bg.jpg') #2D2D2D;
    @media (max-width: 1420px) {
      margin-top: 100px; }
    @media (max-width: 767px) {
      padding: 60px 10px 20px;
      margin-top: 50px; }

    h2 {
      color: #fff;
      margin-bottom: 5px;
      @media (max-width: 767px) {
        font-size: 16px;
        text-align: left; } }

    p {
      font-size: 20px;
      line-height: 130%;
      @media (max-width: 767px) {
        font-size: 14px;
        text-align: left; } }

    form {
      max-width: 936px;
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;

      label, button {
        width: 31%;
        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 20px; } }

      label {
        display: block;
        color: #ACACAC;
        @media (max-width: 767px) {
          &:nth-child(2) {
            margin-bottom: 48px; } } }

      input {
        margin-top: 10px;
        padding: 0 10px;
        display: block;
        width: 100%;
        height: 56px;
        border-radius: 2px;
        font-size: 20px;
        outline: none;
        border: none; } } } }
