section.quality {
  .quality__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .quality__list-item {
      width: 15%;
      @media (max-width: 1220px) {
        width: 30%;
        margin-bottom: 44px; }
      @media (max-width: 767px) {
        width: 48%; }

      .quality__item-img {
        width: 176px;
        height: 176px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #e1e1e1;
        margin: 0 auto;
        margin-bottom: 34px;
        @media (max-width: 1420px) {
          width: 150px;
          height: 150px;
          margin-bottom: 14px;
          // img
          //   width: 80%
 }          //   height: 80%
        @media (max-width: 340px) {
          width: 100px;
          height: 100px;
          margin-bottom: 14px; }

        img {
          width: 80%;
          height: 80%; } }

      .quality__item-text {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.01em;
        @media (max-width: 1420px) {
          font-size: 14px; }
        @media (max-width: 340px) {
          font-size: 10px; } } } } }
