section.hero-branch {
  position: relative;
  @media (max-width: 1420px) {
    padding: 50px 0; }
  @media (max-width: 767px) {
    padding-top: 28px;
    &::before {
      display: none; } }

  &::before {
    @include ba();
    width: 60%;
    height: 700px;
    background: center / cover no-repeat url('../img/pattern.svg');
    z-index: -1;
    right: 0;
    top: 0; }

  .h1-title {
    h1, p {
      background: #fff; } }

  .yellow-list {
    margin-bottom: 140px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 1420px) {
      margin-bottom: 70px; }
    &__item {
      margin: 0 0.6% 20px;
      padding: 30px 42px;
      width: 32%;
      min-height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--accent-yellow);
      text-align: center;
      font-weight: 500;
      font-size: 22px;
      line-height: 130%;
      color: #212121;
      transition: var(--trans-2s);
      &:hover {
        transform: translateY(-5px); }
      @media (max-width: 1420px) {
        font-size: 16px; }
      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 14px; } } }

  .hero-branch__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    &-item {
      display: flex;
      flex-wrap: wrap;
      width: 33.33%;
      min-height: 254px;
      transition: var(--trans-3s);
      overflow: hidden;
      @media (max-width: 1420px) {
        min-height: 150px;
        height: 220px; }
      @media (max-width: 1000px) {
        width: 50%;
        margin-bottom: 34px; }
      @media (max-width: 767px) {
        width: 100%;
        min-height: 150px;
        font-size: 12px;
        &:nth-child(even) {
          flex-direction: row-reverse;
          text-align: right;
          .hero-branch__item-desc {
            text-align: right; } } }
      @media (max-width: 340px) {
        height: 140px; }

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(10),
      &:nth-child(11),
      &:nth-child(12) {
        flex-direction: row-reverse;
        text-align: right;
        @media (max-width: 1000px) {
          flex-direction: row;
          text-align: left; } }

      &:hover {
        .hero-branch__item-img {
          img {
            filter: grayscale(0%); } }
        .hero-branch__item-desc {
          color: var(--accent-yellow); } }

      .hero-branch__item-img {
        width: 50%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          filter: grayscale(100%);
          transition: var(--trans-3s); } }
      .hero-branch__item-desc {
        padding: 20px;
        width: 50%;
        color: #9B9B9B;
        display: flex;
        align-items: center;
        background: #fff;
        transition: var(--trans-3s);
        @media (max-width: 1220px) {
          font-size: 14px; }
        @media (max-width: 767px) {
          padding: 0 8px;
          font-size: 12px; } } } } }
