// HOSE PRODUCT ===================
section.single-product {
  padding: 50px 0;
  padding-bottom: 0;

  .single-product__info {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .single-product__info-left {
      width: 35%;
      @media (max-width: 1000px) {
        width: 100%; }

      .single-product__images {
        .images-item {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid var(--accent-yellow);
          position: relative;
          height: 500px;
          overflow: hidden;
          @media (max-width: 1420px) {
            height: 400px; }
          @media (max-width: 767px) {
            height: 290px; }
          &::before {
            @include ba();
            background: #FBFBFB;
            width: 96%;
            height: 96%;
            top: calc(50% - 48%);
            left: calc(50% - 48%);
            z-index: -1; }
          .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center; }
          .img {
            display: block;
            width: 96%;
            height: 96%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain; } } }

        .images-small {
          margin-top: 30px;
          display: flex;
          justify-content: space-between;
          position: relative;
          overflow: hidden;
          @media (max-width: 1000px) {
            margin-bottom: 40px; }
          &::before, &::after {
            @include ba();
            width: 5%;
            height: 110%;
            background: linear-gradient(90deg, #fff, transparent);
            left: 0px;
            top: 0;
            z-index: 5; }
          &::after {
            width: 10%;
            background: linear-gradient(-90deg, #fff, transparent);
            left: auto;
            right: 0; }

          &-item {
            width: 30%;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 1420px) {
              height: 100px; }
            @media (max-width: 767px) {
              height: 72px; }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover; } }

          .swiper-button-disabled {
            opacity: 0; }
          .swiper-button-prev {
            color: var(--header-black); }
          .swiper-button-next {
            color: var(--header-black); } } } }

    .single-product__info-right {
      width: 60%;
      @media (max-width: 1000px) {
        width: 100%; }

      h2, p {
        background: #fff; }

      h2 {
        @media (max-width: 1420px) {
          font-size: 36px; }
        @media (max-width: 1000px) {
          font-size: 36px;
          display: none; } }

      p {
        font-weight: 700;
        font-size: 32px;
        line-height: 130%;
        text-transform: uppercase;
        margin-bottom: 30px;
        @media (max-width: 1420px) {
          font-size: 22px; }
        @media (max-width: 767px) {
          font-size: 16px; } } } }

  h2.mob {
    display: none;
    @media (max-width: 1000px) {
      display: block;
      font-size: 20px;
      line-height: 130%; } } }

// HOSE BLOCK ===================
section.hose-block {
  padding-top: 0;
  padding-bottom: 0; }

// TECHNOLOGY ===================
section.technology {
  .technology-list {
    &__item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      margin-bottom: 60px;

      &:nth-child(even) {
        flex-direction: row-reverse;
        .technology-list__item-img::before {
          right: 0;
          left: auto;
          border: none;
          border-left: 300px solid transparent;
          border-top: 300px solid var(--accent-yellow);
          @media (max-width: 767px) {
            border-left: 200px solid transparent;
            border-top: 200px solid var(--accent-yellow); } } }
      &:nth-child(3n+2) {
        .technology-list__item-img::before {
          border-top-color: var(--header-black) !important; } }
      &:nth-child(3n+3) {
        .technology-list__item-img::before {
          border-top-color: #EAEAEA !important; } }

      &-img {
        width: 50%;
        position: relative;
        @media (max-width: 1000px) {
          width: 100%;
          margin-bottom: 40px; }
        @media (max-width: 767px) {
          &::before {
            border-right: 200px solid transparent;
            border-top: 200px solid var(--accent-yellow); } }

        &::before {
          @include ba();
          left: 0;
          top: 0;
          border-right: 300px solid transparent;
          border-top: 300px solid var(--accent-yellow);
          z-index: -1; }
        img {
          max-height: 340px; } }

      &-desc {
        width: 50%;
        font-size: 16px;
        line-height: 130%;
        @media (max-width: 1000px) {
          width: 100%; } } } } }

// TABLE ===================
section.table {
  padding-top: 0;
  overflow-x: auto;

  .table-wrapper {
    min-width: 1600px;
    border-radius: 2px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15); }

  table {
    width: 100%;
    font-size: 18px;
    @media (max-width: 1420px) {
      font-size: 16px; }
    thead {
      color: var(--accent-yellow);
      background: var(--header-black);
      th {
        padding: 20px 18px;
        @media (max-width: 1420px) {
          padding: 10px 9px; } } }

    tbody {
      tr {
        &:last-child {
          td {
            border: none; } } }
      td {
        text-align: center;
        border-bottom: 2px solid #BFBFBF;
        @media (max-width: 1420px) {
          &:first-child {
            padding: 10px 9px; } }

        &:first-child {
          padding: 20px 18px; }
        &:nth-child(even) {
          background: #F2F2F2; }
        p {
          padding: 5px 0;
          border-bottom: 1px solid #BFBFBF;
          &:last-child {
            border: none; } } } } }

  .table-wrapper {
    display: flex;
    width: 100%;
    ul {
      flex-grow: 1;
      display: flex;
      &:first-child {
        color: var(--accent-yellow);
        background: var(--header-black); }
      &:not(:first-child) {
        &:nth-child(even) {
          background: #F2F2F2; } }
      li {
        flex-grow: 1;
        padding: 20px 18px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid #BFBFBF; } } } }
