section.counts {
  background: center / cover no-repeat url('../img/counts-bg.jpg');

  .counts__list {
    padding: 45px 60px;
    margin-left: auto;
    width: 55%;
    color: #fff;
    border-radius: 10px;
    background: rgba(0,0,0,.75);
    @media (max-width: 1620px) {
      width: 84%; }
    @media (max-width: 1000px) {
      width: 90%;
      margin: 0 auto;
      padding: 25px 10px; }

    .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }

    &-item {
      width: 30%;
      text-align: center;
      &:nth-child(3) {
        color: var(--accent-yellow); }
      &:nth-child(4) {
        width: 100%;
        margin-top: 44px; }

      .count {
        font-size: 150px;
        font-weight: 700;
        line-height: 120%;
        @media (max-width: 800px) {
          font-size: 48px; } }
      .text {
        font-size: 18px;
        @media (max-width: 800px) {
          font-size: 8px; } } } } }
