@font-face {
  font-family: Mont;
  src: url("../fonts/Mont-Black.woff") format("woff"), url("../fonts/Mont-Black.woff2") format("woff2");
  font-weight: 800;
  font-style: black;
}

@font-face {
  font-family: Mont;
  src: url("../fonts/Mont-Bold.woff") format("woff"), url("../fonts/Mont-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: Mont;
  src: url("../fonts/Mont-Regular.woff") format("woff"), url("../fonts/Mont-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Mont;
  src: url("../fonts/Mont-Light.woff") format("woff"), url("../fonts/Mont-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: lighter;
}

html {
  --accent-yellow: #FFC83C;
  --text-color: #4c4c4c;
  --trans-2s: all .2s ease;
  --trans-3s: all .3s ease;
  --header-black: #2D2D2D;
  --header-black__hover: #212121;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Mont", Arial, sans-serif;
  color: var(--text-color);
}

.container {
  margin: 0 auto;
  max-width: 1600px;
}

section {
  padding: 50px 0;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
}

a {
  color: #333;
  text-decoration: none;
}

.red-text {
  color: #E23A63 !important;
}

.green-text {
  color: #428941 !important;
}

.blue-text {
  color: #2970C2 !important;
}

.yellow-text {
  color: #FFC83C !important;
}

h2 {
  font-size: 45px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--text-color);
  margin-bottom: 60px;
}

.desc {
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: var(--text-color);
}

.yellow-block {
  padding: 5px 25px;
  display: inline-block;
  color: var(--text-color);
  font-size: 22px;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0.01em;
  background: var(--accent-yellow);
}

.yellow-btn {
  width: 225px;
  height: 60px;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: var(--text-color);
  transition: var(--trans-2s);
  background: var(--accent-yellow);
  opacity: 1;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.yellow-btn:hover {
  opacity: .8;
}

.alert-message {
  padding: 20px 12px 20px 46px;
  width: 240px;
  font-size: 14px;
  background: #fbfbfb;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  transition: var(--trans-2s);
  position: absolute;
  right: -30px;
  top: -75px;
  opacity: 0;
  visibility: hidden;
  z-index: 6;
}

.alert-message::before {
  content: '';
  display: block;
  position: absolute;
  width: 26px;
  height: 26px;
  display: inline-block;
  background: center/contain no-repeat url("../img/warning-err.svg");
  top: calc(50% - 13px);
  left: 12px;
}

.yellow-check li {
  margin-bottom: 10px;
  color: var(--accent-yellow);
  font-size: 18px;
  font-weight: 600;
  line-height: 130%;
  position: relative;
  padding-left: 26px;
}

.yellow-check li::before {
  content: '';
  margin-right: 21px;
  display: inline-block;
  width: 14px;
  height: 14px;
  background: center/contain no-repeat url("../img/li-check.svg");
  position: absolute;
  left: 0;
  top: calc(50% - 7px);
}

.h1-title {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 74px;
}

.h1-title.red-line h1::before,
.h1-title.red-line h1::after {
  background-color: #E23A63;
}

.h1-title.green-line h1::before,
.h1-title.green-line h1::after {
  background-color: #428941;
}

.h1-title.blue-line h1::before,
.h1-title.blue-line h1::after {
  background-color: #2970C2;
}

.h1-title.yellow-line h1::before,
.h1-title.yellow-line h1::after {
  background-color: #FFC83C;
}

.h1-title h1 {
  padding: 0 92px;
  color: #4C4C4C;
  font-size: 45px;
  line-height: 130%;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  background: #fff;
}

.h1-title h1::before,
.h1-title h1::after {
  content: '';
  display: block;
  position: absolute;
  width: 72px;
  height: 6px;
  background: #2970C2;
  top: calc(50% - 3px);
}

.h1-title h1::before {
  left: 0px;
}

.h1-title h1::after {
  right: 0px;
}

.h1-title p {
  font-size: 16px;
  line-height: 130%;
  text-align: center;
}

.breadcrumbs {
  margin-bottom: 12px;
  color: #919191;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  background: #fff;
  display: inline-block;
  padding-right: 10px;
}

.breadcrumbs a {
  color: var(--text-color);
  transition: var(--trans-2s);
}

.breadcrumbs a:hover {
  opacity: .8;
}

.fixed-btn {
  width: 220px;
  height: 190px;
  background: center/contain no-repeat url("../img/Btn.png");
  position: fixed;
  bottom: 100px;
  right: 100px;
  animation-name: fixed-btn;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-direction: alternate-reverse;
  z-index: 10;
}

.yellow-lines {
  position: relative;
}

.yellow-lines::before {
  content: '';
  display: block;
  position: absolute;
  width: 60%;
  height: 700px;
  background: center/cover no-repeat url("../img/pattern.svg");
  z-index: -1;
  right: 0;
  top: 0;
}

.hose-block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hose-mobile {
  display: none;
}

.hose-mobile .hose-sys__list-item {
  margin-bottom: 24px;
  z-index: 3;
}

.hose-mobile .hose-sys__list-item .hose-sys__item-title {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.01em;
  margin-bottom: 14px;
}

.hose-mobile .hose-sys__list-item .hose-sys__item-desc {
  color: #8F8F8F;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.01em;
}

@keyframes fixed-btn {
  0% {
    transform: scale(0.1);
  }

  100% {
    transform: scale(1);
  }
}

header {
  background: var(--header-black);
}

header .container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

header .logo {
  width: 350px;
  display: block;
}

header .navbar-wrapper {
  z-index: 100;
}

header .navbar-wrapper .navbar {
  transition: all .3s ease;
}

header .navbar > ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  transition: all .5s ease;
}

header .navbar > ul li {
  position: relative;
}

header .navbar > ul li:hover > a.not-hover {
  background: inherit;
  cursor: auto;
}

header .navbar > ul li:hover > a {
  background: var(--header-black__hover);
}

header .navbar > ul li:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

header .navbar > ul li.has-child > a::after {
  content: '';
  display: block;
  position: absolute;
  position: relative;
  margin-left: 3px;
  display: inline-block;
  border-top: 8px solid white;
  border-right: 5px solid transparent;
  border-bottom: 0.1px solid transparent;
  border-left: 5px solid transparent;
}

header .navbar > ul li a {
  color: #fff;
  display: block;
  padding: 50px 32px;
  text-decoration: none;
  transition: var(--trans-2s);
}

header .navbar > ul li a:hover {
  background: var(--header-black__hover);
}

header .navbar > ul li > ul {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 70%;
  transition: var(--trans-2s);
  background: var(--header-black__hover);
}

header .navbar > ul li > ul .has-child a::after {
  width: 6px;
  height: 6px;
  border: none;
  transform: rotate(-45deg);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  position: absolute;
  top: calc(50% - 1px);
  right: 10px;
}

header .navbar > ul li > ul li:hover a {
  background: #3A3A3A;
}

header .navbar > ul li > ul li:hover ul {
  top: 0;
  left: 100%;
  opacity: 1;
  visibility: visible;
}

header .navbar > ul li > ul li a {
  padding: 15px 20px;
  font-size: 14px;
  transition: var(--trans-2s);
}

header .navbar > ul li > ul li a:hover {
  background: #3A3A3A;
  color: var(--accent-yellow);
}

header .navbar > ul li > ul li ul {
  min-width: 220px;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: 80%;
  position: absolute;
  background: #3a3a3a;
}

header .navbar > ul li > ul li ul li a {
  color: #fff;
}

header .languages__mob {
  display: none;
  margin-bottom: 25px;
  padding: 0 15px;
  position: absolute;
  top: 35px;
  left: 15px;
}

header .languages__mob > div {
  margin-right: 15px;
}

header .contacts-mob {
  display: none;
}

header .contacts-mob .social-list {
  margin: 0 auto;
  justify-content: center;
}

header .contacts-mob .social-list__item {
  width: 45px;
  height: 45px;
}

header .burger {
  display: none;
}

header .burger .burger-line {
  width: 100%;
  height: 2px;
  background: #fff;
  position: relative;
}

header .burger .burger-line::before,
header .burger .burger-line::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
}

header .burger .burger-line::before {
  top: -10px;
}

header .burger .burger-line::after {
  bottom: -10px;
}

header .burger-close {
  display: none;
}

.contacts,
.contacts-mob {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contacts .tel-soc,
.contacts-mob .tel-soc {
  margin-right: 54px;
}

.contacts .number,
.contacts-mob .number {
  color: #fff;
  font-size: 20px;
  position: relative;
}

.contacts .number::before,
.contacts-mob .number::before {
  content: '';
  display: block;
  position: absolute;
  display: inline-block;
  margin-right: 15px;
  position: relative;
  width: 20px;
  height: 20px;
  background: center/contain no-repeat url("../img/y-phone.svg");
}

.contacts .number span,
.contacts-mob .number span {
  color: var(--accent-yellow);
}

.contacts .social-list,
.contacts-mob .social-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 35px;
}

.contacts .social-list__item,
.contacts-mob .social-list__item {
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #3A3A3A;
  transition: var(--trans-2s);
}

.contacts .social-list__item:hover,
.contacts-mob .social-list__item:hover {
  transform: translateY(-5px);
}

.contacts .languages,
.contacts-mob .languages {
  display: flex;
}

.contacts .languages div,
.contacts-mob .languages div {
  width: 40px;
  height: 26px;
  margin-left: 14px;
}

.contacts .languages div:first-child,
.contacts-mob .languages div:first-child {
  margin: 0;
}

section.hero {
  padding: 0;
}

section.hero .hero__list {
  z-index: 1;
  position: relative;
}

section.hero .hero__list .paginations {
  width: 580px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--accent-yellow);
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

section.hero .hero__list .paginations .swiper-pagination {
  width: 75%;
  margin: 0 auto;
}

section.hero .hero__list .paginations .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
}

section.hero .hero__list .paginations .swiper-pagination-bullet-active {
  background: var(--text-color);
}

section.hero .hero__list .paginations .swiper-button-prev,
section.hero .hero__list .paginations .swiper-pagination,
section.hero .hero__list .paginations .swiper-button-next {
  position: relative;
  bottom: 0;
  top: 0;
}

section.hero .hero__list .paginations .swiper-button-prev,
section.hero .hero__list .paginations .swiper-button-next {
  margin-bottom: -20px;
}

section.hero .hero__list .paginations .swiper-button-prev::before,
section.hero .hero__list .paginations .swiper-button-next::before {
  content: '';
  display: block;
  position: absolute;
  width: 21px;
  height: 21px;
  border-left: 3px solid var(--text-color);
  border-bottom: 3px solid var(--text-color);
  border-radius: 2px;
  transform: rotate(45deg);
}

section.hero .hero__list .paginations .swiper-button-prev::after,
section.hero .hero__list .paginations .swiper-button-next::after {
  border-radius: 2px;
  font-size: 0;
  width: 36px;
  height: 3px;
  background: var(--text-color);
}

section.hero .hero__list .paginations .swiper-button-next {
  transform: rotate(180deg);
}

section.hero .hero__list-item {
  position: relative;
  width: 100%;
  height: 725px;
}

section.hero .hero__list-item .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

section.hero .hero__list-item h1,
section.hero .hero__list-item .h1-cont {
  z-index: 2;
  color: #2d2d2d;
  background: var(--accent-yellow);
  display: inline-block;
  font-size: 76px;
  padding: 10px 25px;
  font-weight: 700;
}

section.hero .hero__list-item .h1-cont {
  font-size: 66px;
  padding: 10px 25px;
  margin-top: -30px;
}

section.hero .hero__list-item .hero-img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

section.hero .hero__list-item .hero-img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

section.hose-sys {
  position: relative;
}

section.hose-sys .materials__promo__section .section__heading {
  display: none;
}

section.hose-sys h2.mob {
  display: none;
}

section.hose-sys .pattern {
  width: 60%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

section.hose-sys .hose-block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

section.hose-sys .yellow-lines {
  z-index: 2;
  position: absolute;
  right: 170px;
  top: 320px;
}

section.hose-sys .desc {
  max-width: 800px;
}

section.hose-sys .yellow-block {
  max-width: 730px;
  display: block;
  text-align: center;
  margin-top: 60px;
  font-size: 45px;
}

.materials__promo__section {
  padding: 100px 0 150px;
}

.materials__promo__section .container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 15px;
}

.materials-promo__container {
  position: relative;
  z-index: 1;
}

.materials__promo__section .section__heading {
  font-size: 45px;
  font-size: 2.8125rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #4C4C4C;
  margin-bottom: 30px;
}

.materials-promo__inner {
  position: absolute;
  width: 100%;
  height: 650px;
  top: 0;
  left: 0;
}

.materials-promo-text__item {
  position: absolute;
  text-align: right;
  max-width: 300px;
}

.__promo-text__item1 {
  top: 0;
  left: 0;
}

.materials-promo-text__item__inner {
  position: relative;
}

.prompt-line__wrapper {
  position: absolute;
}

.prompt-line__wrapper1 {
  width: 809px;
  top: 133px;
  right: calc(-100% - 520px);
  transform: rotate(18deg);
  transform: rotate(198deg);
}

.prompt-line {
  background: #FFC83C;
  height: 2px;
  position: absolute;
  animation-duration: 2s;
  animation-iteration-count: 1;
  opacity: 0;
}

.__promo-text__item1 .prompt-line {
  width: 805px;
  width: 100%;
  transform: rotate(180deg);
}

.prompt-line::before,
.prompt-line:after {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #FFC83C;
  display: block;
  position: absolute;
  top: -4px;
}

.prompt-line.dots::before {
  animation-name: dot_animation;
  animation-duration: 1600ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.prompt-line::before {
  left: 0;
  border: 3px solid #2D2D2D;
  top: -6px;
  opacity: 0;
}

.prompt-line:after {
  right: 0;
  /* border: 3px solid #2D2D2D; */
}

.__promo-text__item2 {
  top: 42%;
  left: 0;
}

.prompt-line__wrapper2 {
  width: 655px;
  width: 659px;
  top: 5px;
  right: calc(-100% - 380px);
  transform: rotate(-2deg);
  transform: rotate(-182deg);
}

.__promo-text__item2 .prompt-line {
  width: 100%;
  transform: rotate(-180deg);
}

.__promo-text__item3 {
  right: 100px;
  bottom: 0;
}

.prompt-line__wrapper3 {
  width: 284px;
  top: -125px;
  right: calc(-100% + 110px);
  transform: rotate(-80deg);
  transform: rotate(-261deg);
}

.__promo-text__item3 .prompt-line {
  width: 100%;
  transform: rotate(-180deg);
}

.container__large {
  max-width: 1600px;
  padding: 0;
}

.cable-img {
  display: block;
  margin-left: auto;
  max-width: 900px;
  margin-top: 200px;
  margin-top: 12.5rem;
  max-width: 56.25rem;
}

.cable-img-mobile {
  display: none;
  max-width: 100%;
  margin-bottom: 30px;
}

.materials-promo-text__item__inner span {
  color: #2D2D2D;
  font-size: 18px;
  font-weight: 700;
}

.materials-promo-text__item__inner p {
  color: #8F8F8F;
}

@keyframes line_animation {
  0% {
    width: 0px;
    visibility: hidden;
  }

  100% {
    width: 100%;
    visibility: visible;
  }
}

@keyframes dot_animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

section.counts {
  background: center/cover no-repeat url("../img/counts-bg.jpg");
}

section.counts .counts__list {
  padding: 45px 60px;
  margin-left: auto;
  width: 55%;
  color: #fff;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.75);
}

section.counts .counts__list .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

section.counts .counts__list-item {
  width: 30%;
  text-align: center;
}

section.counts .counts__list-item:nth-child(3) {
  color: var(--accent-yellow);
}

section.counts .counts__list-item:nth-child(4) {
  width: 100%;
  margin-top: 44px;
}

section.counts .counts__list-item .count {
  font-size: 150px;
  font-weight: 700;
  line-height: 120%;
}

section.counts .counts__list-item .text {
  font-size: 18px;
}

section.quality .quality__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

section.quality .quality__list .quality__list-item {
  width: 15%;
}

section.quality .quality__list .quality__list-item .quality__item-img {
  width: 176px;
  height: 176px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #e1e1e1;
  margin: 0 auto;
  margin-bottom: 34px;
}

section.quality .quality__list .quality__list-item .quality__item-img img {
  width: 80%;
  height: 80%;
}

section.quality .quality__list .quality__list-item .quality__item-text {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
}

section.solution {
  padding-top: 0;
}

section.solution .solution__list {
  display: flex;
  justify-content: space-between;
}

section.solution .solution__list-item {
  width: 33.33%;
  height: 360px;
  position: relative;
  overflow: hidden;
  transition: all .3s ease;
}

section.solution .solution__list-item:hover {
  width: 70%;
}

section.solution .solution__list-item:hover .top-el__title {
  color: #fff;
  text-align: left;
  position: absolute;
  left: 35px;
  top: 50px;
}

section.solution .solution__list-item:hover .bottom-el {
  left: 0;
}

section.solution .solution__list-item:nth-child(1) .bottom-el::before {
  background: #2970C2;
}

section.solution .solution__list-item:nth-child(2) .bottom-el::before {
  background: #E23A63;
}

section.solution .solution__list-item:nth-child(3) .bottom-el::before {
  background: #428941;
}

section.solution .top-el {
  padding: 0 15px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

section.solution .top-el__title {
  color: var(--accent-yellow);
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  transition: all .3s ease;
}

section.solution .top-el__img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

section.solution .top-el__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

section.solution .bottom-el {
  margin-top: 24px;
  padding: 140px 22px 30px;
  width: 100%;
  height: 100%;
  padding-top: 140px;
  position: absolute;
  left: -900px;
  top: 0;
  transition: all .3s ease;
}

section.solution .bottom-el::before {
  content: '';
  display: block;
  position: absolute;
  width: 1100px;
  height: 900px;
  top: -80px;
  left: -390px;
  transform: rotate(45deg);
  z-index: -1;
  transition: all .3s ease;
}

section.solution .bottom-el ul li a {
  display: inline-block;
  padding: 8px 20px 8px 12px;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.01em;
  transition: var(--trans-2s);
}

section.solution .bottom-el ul li a:hover {
  color: var(--accent-yellow);
  background: var(--header-black);
}

section.solution .bottom-el ul li a:hover::after {
  border-color: var(--accent-yellow);
}

section.solution .bottom-el ul li a::after {
  content: '';
  display: block;
  position: absolute;
  position: relative;
  display: inline-block;
  margin-left: 5px;
  width: 8px;
  height: 8px;
  transform: rotate(-45deg);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

section.our-partners {
  overflow: hidden;
  padding-top: 0;
}

section.our-partners .our-partners__list-container {
  max-height: 500px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

section.our-partners .our-partners__list-item {
  margin-bottom: 48px;
  margin-right: 54px;
  min-width: 15%;
  height: 200px;
  animation-name: partnersCarousel-2400;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-delay: 3s;
}

@keyframes partnersCarousel-2400 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-2400px);
  }
}

@keyframes partnersCarousel-1000 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-1000px);
  }
}

@keyframes partnersCarousel-276 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-276px);
  }
}

section.constructor {
  padding-top: 0;
  overflow: hidden;
}

section.constructor .form-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

section.constructor .form-container .input-div {
  width: 48%;
}

section.constructor .form-container .pressure-input,
section.constructor .form-container .input-div {
  position: relative;
}

section.constructor .form-container .pressure-input::after,
section.constructor .form-container .input-div::after {
  content: '';
  display: block;
  position: absolute;
  content: 'Bar.';
  font-size: 16px;
  color: var(--accent-yellow);
  right: 5px;
  top: 23px;
}

section.constructor .form-container .pressure-input .pressure,
section.constructor .form-container .input-div .pressure {
  width: 100%;
  padding-right: 45px;
}

section.constructor .form-container .input-div::after {
  content: 'M.';
}

section.constructor .form-container .input-div input {
  padding-right: 30px !important;
}

section.constructor .form-container .form-item {
  width: 18%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 20px;
}

section.constructor .form-container .form-item:nth-child(2) .jq-selectbox__dropdown li:nth-child(2)::before {
  background-image: url("../img/a-cpt-1.1.png");
}

section.constructor .form-container .form-item:nth-child(2) .jq-selectbox__dropdown li:nth-child(2)::after {
  background-image: url("../img/a-cpt-1.2.png");
}

section.constructor .form-container .form-item:nth-child(2) .jq-selectbox__dropdown li:nth-child(3)::before {
  background-image: url("../img/a-cpt-1.1.png");
}

section.constructor .form-container .form-item:nth-child(2) .jq-selectbox__dropdown li:nth-child(3)::after {
  background-image: url("../img/a-cpt-2.1.png");
}

section.constructor .form-container .form-item:nth-child(2) .jq-selectbox__dropdown li:nth-child(4)::before {
  background-image: url("../img/a-cpt-3.1.png");
}

section.constructor .form-container .form-item:nth-child(4) .jq-selectbox__dropdown li:nth-child(2)::before {
  background-image: url("../img/a-cpt-1.1.png");
}

section.constructor .form-container .form-item:nth-child(4) .jq-selectbox__dropdown li:nth-child(3)::before {
  background-image: url("../img/conn-2.png");
}

section.constructor .form-container .form-item:nth-child(4) .jq-selectbox__dropdown li:nth-child(4)::before {
  background-image: url("../img/conn-3.png");
}

section.constructor .form-container .form-item:nth-child(4) .jq-selectbox__dropdown li:nth-child(5)::before {
  background-image: url("../img/conn-4.png");
}

section.constructor .form-container .form-item:nth-child(4) .jq-selectbox__dropdown li:nth-child(6)::before {
  background-image: url("../img/conn-5.png");
}

section.constructor .form-container .form-item:nth-child(4) .jq-selectbox__dropdown li:nth-child(7)::before {
  background-image: url("../img/conn-6.png");
}

section.constructor .form-container .form-item .jq-selectbox__dropdown ul li {
  position: relative;
  padding-right: 75px !important;
}

section.constructor .form-container .form-item .jq-selectbox__dropdown ul li::before,
section.constructor .form-container .form-item .jq-selectbox__dropdown ul li::after {
  content: '';
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  background: center / contain no-repeat;
  top: calc(50% - 15px);
  right: 12px;
  z-index: 11;
}

section.constructor .form-container .form-item .jq-selectbox__dropdown ul li::after {
  right: 40px;
}

section.constructor .form-container .form-item .alert-message {
  display: none;
}

section.constructor .form-container .form-item .jq-selectbox.err,
section.constructor .form-container .form-item .div-input.err,
section.constructor .form-container .form-item input.err {
  background: rgba(255, 0, 0, 0.05) !important;
}

section.constructor .form-container .form-item .jq-selectbox.err::before,
section.constructor .form-container .form-item .div-input.err::before,
section.constructor .form-container .form-item input.err::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.05) !important;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  cursor: auto;
  transition: var(--trans-2s);
}

section.constructor .form-container .form-item.disabled-sel {
  position: relative;
}

section.constructor .form-container .form-item.disabled-sel .jq-selectbox,
section.constructor .form-container .form-item.disabled-sel .input-div {
  position: relative;
}

section.constructor .form-container .form-item.disabled-sel .jq-selectbox:hover::before,
section.constructor .form-container .form-item.disabled-sel .input-div:hover::before {
  background: rgba(255, 0, 0, 0.05);
}

section.constructor .form-container .form-item.disabled-sel .jq-selectbox:hover .alert-message,
section.constructor .form-container .form-item.disabled-sel .input-div:hover .alert-message {
  opacity: 1;
  visibility: visible;
  top: -85px;
  z-index: 100;
}

section.constructor .form-container .form-item.disabled-sel .jq-selectbox::before,
section.constructor .form-container .form-item.disabled-sel .input-div::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  cursor: auto;
  transition: var(--trans-2s);
}

section.constructor .form-container .form-item.disabled-sel .jq-selectbox .alert-message,
section.constructor .form-container .form-item.disabled-sel .input-div .alert-message {
  display: block;
  padding: 20px 12px 20px 46px;
  width: 240px;
  font-size: 14px;
  background: #fbfbfb;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  transition: var(--trans-2s);
  position: absolute;
  right: -30px;
  top: -75px;
  opacity: 0;
  visibility: hidden;
  z-index: 6;
}

section.constructor .form-container .form-item.disabled-sel .jq-selectbox .alert-message::before,
section.constructor .form-container .form-item.disabled-sel .input-div .alert-message::before {
  content: '';
  display: block;
  position: absolute;
  width: 26px;
  height: 26px;
  display: inline-block;
  background: center/contain no-repeat url("../img/warning-err.svg");
  top: calc(50% - 13px);
  left: 12px;
}

section.constructor .form-container .form-item__title {
  margin-bottom: 10px;
  position: relative;
}

section.constructor .form-container .form-item__title:hover .help-text {
  opacity: 1;
  visibility: visible;
}

section.constructor .form-container .form-item__title .help-icon {
  width: 15px;
  height: 15px;
  display: inline-block;
  background: center/contain no-repeat url("../img/form-help.svg");
  position: absolute;
  right: -20px;
  top: 5px;
}

section.constructor .form-container .form-item__title .help-text {
  padding: 12px;
  width: 235px;
  border-radius: 2px;
  background: #FBFBFB;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  transition: var(--trans-2s);
  position: absolute;
  bottom: 0;
  left: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  font-size: 16px;
}

section.constructor .form-container .form-item input {
  padding: 0 25px;
  font-size: 20px;
  border: none;
  outline: none;
  background-color: #FBFBFB;
  height: 65px;
  line-height: 65;
}

section.constructor .form-container .form-item input::placeholder {
  color: #ACACAC;
}

section.constructor .form-container .form-item .diameter,
section.constructor .form-container .form-item .pressure-input {
  display: inline-block;
  width: 48%;
}

section.constructor .form-container .form-item .length {
  width: 100%;
}

section.constructor .form-container .reset {
  margin-top: 55px;
  background: transparent;
  text-decoration-line: underline;
  color: var(--accent-yellow);
  line-height: 26px;
  font-size: 20px;
  cursor: pointer;
  border: none;
}

section.constructor .form-container .jq-selectbox {
  width: 100%;
  margin-bottom: 14px;
}

section.constructor .form-container .jq-selectbox.changed .jq-selectbox__select {
  color: var(--text-color);
}

section.constructor .form-container .jq-selectbox .jq-selectbox__select {
  padding: 0 25px;
  height: 64px;
  display: flex;
  align-items: center;
  background: #FBFBFB;
  box-shadow: none;
  color: #ACACAC;
  border: none;
}

section.constructor .form-container .jq-selectbox .jq-selectbox__select-text {
  font-size: 20px;
}

section.constructor .form-container .jq-selectbox .jq-selectbox__trigger {
  border: none;
}

section.constructor .form-container .jq-selectbox .jq-selectbox__trigger-arrow {
  width: 10px;
  height: 10px;
  top: 22px;
  border: none;
  border-top: 2px solid var(--accent-yellow);
  border-right: 2px solid var(--accent-yellow);
  transform: rotate(135deg);
}

section.constructor .form-container .jq-selectbox .jq-selectbox__dropdown {
  min-width: 100%;
  width: auto !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

section.constructor .form-container .jq-selectbox .jq-selectbox__dropdown ul {
  padding: 10px 16px;
  background: #fbfbfb;
}

section.constructor .form-container .jq-selectbox .jq-selectbox__dropdown li {
  font-size: 20px;
  border-radius: 8px;
  padding: 8px 12px;
}

section.constructor .form-container .jq-selectbox .jq-selectbox__dropdown li:hover {
  background-color: var(--accent-yellow);
}

section.constructor .form-container .jq-selectbox .jq-selectbox__dropdown li.selected {
  background-color: var(--accent-yellow);
}

section.constructor .form-previews {
  margin-top: 50px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 20px;
}

section.constructor .form-previews__item {
  width: 30%;
  height: 464px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--accent-yellow);
  color: var(--accent-yellow);
  text-align: center;
  position: relative;
}

section.constructor .form-previews__item::before {
  content: '';
  display: block;
  position: absolute;
  width: 92%;
  height: 92%;
  background: #fbfbfb;
  top: calc(50% - 46%);
  left: calc(50% - 46%);
  z-index: -1;
}

section.constructor .form-previews__item-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92%;
  height: 92%;
  position: absolute;
  top: calc(50% - 46%);
  left: calc(50% - 46%);
}

section.constructor .form-previews__item-img img {
  max-width: 100%;
  max-height: 100%;
}

section.constructor .order {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

section.constructor .order .free-consultation {
  margin-right: 26px;
  font-size: 20px;
}

section.promline-standards {
  padding: 80px 0 100px;
  margin-bottom: 150px;
  color: #fff;
  background: center/cover no-repeat url("../img/tabs-bg.jpg");
}

section.promline-standards .promline-standards__title {
  font-size: 35px;
  font-weight: 700;
  line-height: 130%;
  text-transform: uppercase;
}

section.promline-standards .promline-standards__tabs {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

section.promline-standards .promline-standards__tabs ul.tabs-title__list {
  width: 40%;
  counter-reset: tabs-list;
}

section.promline-standards .promline-standards__tabs ul.tabs-title__list li {
  counter-increment: tabs-list;
  margin-bottom: 10px;
  padding: 20px 50px 20px 50px;
  font-size: 18px;
  font-weight: 600;
  line-height: 130%;
  cursor: pointer;
  transition: var(--trans-2s);
  border-radius: 2px;
  position: relative;
}

section.promline-standards .promline-standards__tabs ul.tabs-title__list li:hover {
  color: var(--accent-yellow);
  background-color: #3A3A3A;
}

section.promline-standards .promline-standards__tabs ul.tabs-title__list li:hover::after {
  border-color: var(--accent-yellow);
}

section.promline-standards .promline-standards__tabs ul.tabs-title__list li::before {
  margin-right: 10px;
  content: counter(tabs-list) ")";
  height: 22px;
  position: absolute;
  top: calc(50% - 11px);
  left: 18px;
  display: flex;
  align-items: center;
}

section.promline-standards .promline-standards__tabs ul.tabs-title__list li::after {
  content: '';
  display: block;
  position: absolute;
  right: 28px;
  top: calc(50% - 6px);
  transform: rotate(-45deg);
  width: 12px;
  height: 12px;
  transition: var(--trans-2s);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

section.promline-standards .promline-standards__tabs ul.tabs-title__list li.active {
  color: var(--text-color);
  background: var(--accent-yellow);
}

section.promline-standards .promline-standards__tabs ul.tabs-title__list li.active::after {
  border-color: var(--text-color);
}

section.promline-standards .promline-standards__tabs .tabs-content__list {
  width: 55%;
}

section.promline-standards .promline-standards__tabs .tabs-content__list-item .tabs-content__item-title {
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 30px;
  line-height: 130%;
}

section.promline-standards .promline-standards__tabs .tabs-content__list-item .tabs-content__item-content {
  margin-bottom: 34px;
  display: flex;
  justify-content: space-between;
}

section.promline-standards .promline-standards__tabs .tabs-content__list-item .tabs-content__item-desc {
  color: #CECECE;
  font-size: 18px;
  line-height: 130%;
}

section.promline-standards .promline-standards__tabs .tabs-content__list-item .tabs-content__item-img {
  margin-left: 25px;
  min-width: 45%;
  overflow: hidden;
  border-radius: 5px;
}

section.hero-branch {
  position: relative;
}

section.hero-branch::before {
  content: '';
  display: block;
  position: absolute;
  width: 60%;
  height: 700px;
  background: center/cover no-repeat url("../img/pattern.svg");
  z-index: -1;
  right: 0;
  top: 0;
}

section.hero-branch .h1-title h1,
section.hero-branch .h1-title p {
  background: #fff;
}

section.hero-branch .yellow-list {
  margin-bottom: 140px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

section.hero-branch .yellow-list__item {
  margin: 0 0.6% 20px;
  padding: 30px 42px;
  width: 32%;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--accent-yellow);
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  color: #212121;
  transition: var(--trans-2s);
}

section.hero-branch .yellow-list__item:hover {
  transform: translateY(-5px);
}

section.hero-branch .hero-branch__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

section.hero-branch .hero-branch__list-item {
  display: flex;
  flex-wrap: wrap;
  width: 33.33%;
  min-height: 254px;
  transition: var(--trans-3s);
  overflow: hidden;
}

section.hero-branch .hero-branch__list-item:nth-child(4),
section.hero-branch .hero-branch__list-item:nth-child(5),
section.hero-branch .hero-branch__list-item:nth-child(6),
section.hero-branch .hero-branch__list-item:nth-child(10),
section.hero-branch .hero-branch__list-item:nth-child(11),
section.hero-branch .hero-branch__list-item:nth-child(12) {
  flex-direction: row-reverse;
  text-align: right;
}

section.hero-branch .hero-branch__list-item:hover .hero-branch__item-img img {
  filter: grayscale(0%);
}

section.hero-branch .hero-branch__list-item:hover .hero-branch__item-desc {
  color: var(--accent-yellow);
}

section.hero-branch .hero-branch__list-item .hero-branch__item-img {
  width: 50%;
}

section.hero-branch .hero-branch__list-item .hero-branch__item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
  transition: var(--trans-3s);
}

section.hero-branch .hero-branch__list-item .hero-branch__item-desc {
  padding: 20px;
  width: 50%;
  color: #9B9B9B;
  display: flex;
  align-items: center;
  background: #fff;
  transition: var(--trans-3s);
}

footer {
  color: #fff;
  padding: 100px 0 80px;
  background: center/cover no-repeat url("../img/footer.jpg");
  z-index: 12;
}

footer .wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

footer ul {
  width: 18%;
}

footer ul li:first-child a {
  cursor: auto;
  display: block;
  font-size: 20px;
  line-height: 26px;
  color: #FFC83B;
  margin-bottom: 22px;
}

footer ul li:not(:first-child):hover a {
  color: #fff;
}

footer ul li a {
  color: #ACACAC;
  font-size: 16px;
  font-weight: 300;
  line-height: 130%;
  display: block;
  margin-bottom: 18px;
  transition: var(--trans-2s);
}

footer .contacts {
  display: block;
}

footer .tel-soc {
  margin-bottom: 40px;
}

footer .social-list {
  margin-left: 0 !important;
}

footer .number::before {
  display: none !important;
}

footer .footer-logo {
  width: 240px;
  margin: 100px auto 0;
}

.modal-form {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  transition: var(--trans-2s);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.modal-form.active {
  opacity: 1;
  visibility: visible;
  z-index: 100;
}

.modal-form::before,
.modal-form::after {
  content: '';
  display: block;
  position: absolute;
  width: 60%;
  height: 260px;
  background: center/cover no-repeat url("../img/pattern.svg");
  z-index: -1;
}

.modal-form::before {
  bottom: 0;
  left: 0;
  transform: rotate(180deg);
}

.modal-form::after {
  right: 0;
  top: 0;
}

.modal-form .form-container {
  width: 886px;
  padding: 60px 20px;
  background: var(--header-black__hover);
}

.modal-form .form-container__title {
  max-width: 434px;
  margin: 0 auto 34px;
  text-align: center;
  color: #fff;
  font-size: 45px;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  position: relative;
}

.modal-form .form-container__title::before,
.modal-form .form-container__title::after {
  content: '';
  display: block;
  position: absolute;
  width: 72px;
  height: 6px;
  background: #FFC83C;
  top: calc(50% - 3px);
}

.modal-form .form-container__title::before {
  left: -87px;
}

.modal-form .form-container__title::after {
  right: -87px;
}

.modal-form .form-container__done {
  display: none;
  max-width: 432px;
  color: #fff;
  text-align: center;
  line-height: 140%;
  margin: 0 auto;
}

.modal-form .form-container__done.active {
  display: block;
}

.modal-form .form-container__done a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px auto 0;
}

.modal-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-form form input {
  margin-bottom: 24px;
  padding: 20px 24px;
  display: block;
  width: 290px;
  font-size: 20px;
  line-height: 26px;
  outline: none;
}

.modal-form form input::placeholder {
  color: #ACACAC;
}

.modal-form .input-div {
  position: relative;
}

.modal-form .input-div.err input {
  transition: var(--trans-2s);
  background: #FFEDED;
}

.modal-form .input-div.err .alert-message {
  top: -55px;
  opacity: 1;
  visibility: visible;
}

.error-404 {
  padding: 50px 20px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--accent-yellow);
}

.error-404-img {
  max-width: 936px;
  margin-bottom: 103px;
}

.error-404-title {
  font-weight: 700;
  font-size: 45px;
  line-height: 58px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.error-404-desc {
  line-height: 24px;
  text-align: center;
  max-width: 452px;
  font-weight: 400;
  font-size: 16px;
}

.error-404-btn {
  width: 242px;
  height: 56px;
  border-radius: 2px;
  background: #FFFFFF;
  box-shadow: 0px 2px 5px 2px rgba(254, 137, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  transition: var(--trans-2s);
}

.error-404-btn:hover {
  opacity: .8;
}

section.contacts {
  display: block;
  padding: 100px 0;
  position: relative;
}

section.contacts::before {
  content: '';
  display: block;
  position: absolute;
  width: 60%;
  height: 350px;
  background: center/cover no-repeat url("../img/pattern-bw.svg");
  right: 0;
  top: 0;
  z-index: -1;
}

section.contacts h4 {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  text-transform: uppercase;
}

section.contacts .constacts-top {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
}

section.contacts .constacts-top .constacts-socials {
  padding: 70px 50px;
  width: 25%;
  color: #fff;
  background: var(--accent-yellow);
  box-shadow: 0px 3.03633px 6.07266px rgba(0, 0, 0, 0.25);
}

section.contacts .constacts-top .constacts-socials h4 {
  text-align: center;
  margin-bottom: 42px;
}

section.contacts .constacts-top .constacts-socials__list-item {
  padding: 15px 20px;
  margin-bottom: 25px;
  border-radius: 3px;
  box-sizing: border-box;
  border: 2px solid #FFFFFF;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 18px;
  transition: var(--trans-2s);
}

section.contacts .constacts-top .constacts-socials__list-item:hover {
  transform: translateX(10px);
}

section.contacts .constacts-top .constacts-socials__list-item::before {
  content: '';
  margin-right: 18px;
}

section.contacts .constacts-top .constacts-socials__list-item:nth-child(1)::before {
  width: 44px;
  height: 30px;
  background: center/contain no-repeat url("../img/ytb-w.svg");
}

section.contacts .constacts-top .constacts-socials__list-item:nth-child(2) {
  margin-bottom: 0;
}

section.contacts .constacts-top .constacts-socials__list-item:nth-child(2)::before {
  width: 30px;
  height: 30px;
  background: center/contain no-repeat url("../img/inst-w.svg");
}

section.contacts .constacts-top .contacts-info {
  padding: 70px 50px;
  width: 70%;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: var(--accent-yellow);
  box-shadow: 0px 3.03633px 6.07266px rgba(0, 0, 0, 0.25);
}

section.contacts .constacts-top .contacts-info h4 {
  margin-bottom: 30px;
}

section.contacts .constacts-top .contacts-info__item {
  flex-grow: 1;
  padding: 0 5px;
}

section.contacts .constacts-top .contacts-info__item:nth-child(1) ul li:nth-child(1)::before {
  background-image: url("../img/map.svg");
}

section.contacts .constacts-top .contacts-info__item:nth-child(1) ul li:nth-child(2)::before {
  background-image: url("../img/phone.svg");
}

section.contacts .constacts-top .contacts-info__item:nth-child(1) ul li:nth-child(3)::before {
  background-image: url("../img/mail.svg");
}

section.contacts .constacts-top .contacts-info__item:nth-child(2) ul li::before {
  background-image: url("../img/map.svg");
}

section.contacts .constacts-top .contacts-info__item:nth-child(3) ul li:nth-child(1)::before {
  background-image: url("../img/tlgrm.svg");
}

section.contacts .constacts-top .contacts-info__item:nth-child(3) ul li:nth-child(2)::before {
  background-image: url("../img/whtsp.svg");
}

section.contacts .constacts-top .contacts-info__item:nth-child(3) ul li:nth-child(3)::before {
  background-image: url("../img/vbr.svg");
}

section.contacts .constacts-top .contacts-info ul li {
  margin-bottom: 20px;
  color: var(--text-color);
  padding-left: 25px;
  position: relative;
}

section.contacts .constacts-top .contacts-info ul li::before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background: center / contain no-repeat;
  position: absolute;
  left: 0;
  top: 0;
}

section.contacts .constacts-top .contacts-info ul li a {
  text-transform: uppercase;
  transition: var(--trans-2s);
}

section.contacts .constacts-top .contacts-info ul li a:hover {
  opacity: .8;
}

section.contacts .contacts-form {
  margin-top: 150px;
  padding: 64px 50px;
  color: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  background: center/cover no-repeat url("../img/cf-bg.jpg") #2D2D2D;
}

section.contacts .contacts-form h2 {
  color: #fff;
  margin-bottom: 5px;
}

section.contacts .contacts-form p {
  font-size: 20px;
  line-height: 130%;
}

section.contacts .contacts-form form {
  max-width: 936px;
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
}

section.contacts .contacts-form form label,
section.contacts .contacts-form form button {
  width: 31%;
}

section.contacts .contacts-form form label {
  display: block;
  color: #ACACAC;
}

section.contacts .contacts-form form input {
  margin-top: 10px;
  padding: 0 10px;
  display: block;
  width: 100%;
  height: 56px;
  border-radius: 2px;
  font-size: 20px;
  outline: none;
  border: none;
}

section.products .products-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

section.products .products-list .products-list__item {
  margin-bottom: 100px;
  padding: 45px;
  width: 44%;
  min-height: 320px;
  background: #fff;
  border: 2px solid var(--accent-yellow);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

section.products .products-list .products-list__item::before {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-right: 310px solid transparent;
  border-bottom: 310px solid #E23A63;
  bottom: 35px;
  left: 40px;
  z-index: 0;
}

section.products .products-list .products-list__item.red::before {
  border-bottom-color: #E23A63;
}

section.products .products-list .products-list__item.green::before {
  border-bottom-color: #428941;
}

section.products .products-list .products-list__item.blue::before {
  border-bottom-color: #2970C2;
}

section.products .products-list .products-list__item.yellow::before {
  border-bottom-color: #FFC83C;
}

section.products .products-list .products-list__item.light-blue::before {
  border-bottom-color: #85A2C5;
}

section.products .products-list .products-list__item.light-yellow::before {
  border-bottom-color: #EDB561;
}

section.products .products-list .products-list__item-title {
  font-size: 28px;
  font-weight: 700;
  text-align: right;
  text-transform: uppercase;
}

section.products .products-list .products-list__item-img {
  max-width: 300px;
  height: 190px;
  margin-left: 80px;
  transform: translate(-45px, 0);
  display: flex;
  align-items: flex-end;
}

section.products .products-list .products-list__item-btn {
  margin-left: auto;
  width: 200px;
  transform: translateY(-25px);
}

section.products .products-list .products-list__item-more {
  padding: 45px;
  width: 100%;
  height: 100%;
  color: #fff;
  background: var(--accent-yellow);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: var(--trans-3s);
  overflow-y: auto;
}

section.products .products-list .products-list__item-more .products-list__item-title {
  text-align: left;
  transform: translateX(10px);
}

section.products .products-list .products-list__item-more ul {
  margin-top: 20px;
}

section.products .products-list .products-list__item-more ul li a {
  padding: 15px 12px;
  margin-bottom: 5px;
  display: inline-block;
  font-size: 20px;
  color: #fff;
}

section.products .products-list .products-list__item-more ul li a:hover {
  background: var(--header-black);
}

section.products .products-list .products-list__item-more ul li a:hover ~ .img {
  opacity: 1;
  visibility: visible;
}

section.products .products-list .products-list__item-more ul li .img {
  max-width: 240px;
  height: 190px;
  opacity: 0;
  visibility: hidden;
  transition: var(--trans-2s);
  position: absolute;
  right: 75px;
  top: calc(50% - 95px);
}

section.products .products-list .products-list__item-btn:focus ~ .products-list__item-more {
  opacity: 1;
  visibility: visible;
}

section.products .products-list .products-list__item__soon {
  margin: 0 auto;
}

section.products .products-list .products-list__item__soon .products-list__item-title {
  margin-bottom: 54px;
  text-align: center;
  color: #6c6c6c;
}

section.products .products-list .products-list__item__soon img {
  margin: 0 auto;
  display: block;
  transform: translateX(-25px);
  filter: contrast(0.3);
}

section.single-product {
  padding: 50px 0;
  padding-bottom: 0;
}

section.single-product .single-product__info {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

section.single-product .single-product__info .single-product__info-left {
  width: 35%;
}

section.single-product .single-product__info .single-product__info-left .single-product__images .images-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--accent-yellow);
  position: relative;
  height: 500px;
  overflow: hidden;
}

section.single-product .single-product__info .single-product__info-left .single-product__images .images-item::before {
  content: '';
  display: block;
  position: absolute;
  background: #FBFBFB;
  width: 96%;
  height: 96%;
  top: calc(50% - 48%);
  left: calc(50% - 48%);
  z-index: -1;
}

section.single-product .single-product__info .single-product__info-left .single-product__images .images-item .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

section.single-product .single-product__info .single-product__info-left .single-product__images .images-item .img {
  display: block;
  width: 96%;
  height: 96%;
  display: flex;
  align-items: center;
  justify-content: center;
}

section.single-product .single-product__info .single-product__info-left .single-product__images .images-item .img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

section.single-product .single-product__info .single-product__info-left .single-product__images .images-small {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

section.single-product .single-product__info .single-product__info-left .single-product__images .images-small::before,
section.single-product .single-product__info .single-product__info-left .single-product__images .images-small::after {
  content: '';
  display: block;
  position: absolute;
  width: 5%;
  height: 110%;
  background: linear-gradient(90deg, #fff, transparent);
  left: 0px;
  top: 0;
  z-index: 5;
}

section.single-product .single-product__info .single-product__info-left .single-product__images .images-small::after {
  width: 10%;
  background: linear-gradient(-90deg, #fff, transparent);
  left: auto;
  right: 0;
}

section.single-product .single-product__info .single-product__info-left .single-product__images .images-small-item {
  width: 30%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

section.single-product .single-product__info .single-product__info-left .single-product__images .images-small-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

section.single-product .single-product__info .single-product__info-left .single-product__images .images-small .swiper-button-disabled {
  opacity: 0;
}

section.single-product .single-product__info .single-product__info-left .single-product__images .images-small .swiper-button-prev {
  color: var(--header-black);
}

section.single-product .single-product__info .single-product__info-left .single-product__images .images-small .swiper-button-next {
  color: var(--header-black);
}

section.single-product .single-product__info .single-product__info-right {
  width: 60%;
}

section.single-product .single-product__info .single-product__info-right h2,
section.single-product .single-product__info .single-product__info-right p {
  background: #fff;
}

section.single-product .single-product__info .single-product__info-right p {
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  text-transform: uppercase;
  margin-bottom: 30px;
}

section.single-product h2.mob {
  display: none;
}

section.hose-block {
  padding-top: 0;
  padding-bottom: 0;
}

section.technology .technology-list__item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 60px;
}

section.technology .technology-list__item:nth-child(even) {
  flex-direction: row-reverse;
}

section.technology .technology-list__item:nth-child(even) .technology-list__item-img::before {
  right: 0;
  left: auto;
  border: none;
  border-left: 300px solid transparent;
  border-top: 300px solid var(--accent-yellow);
}

section.technology .technology-list__item:nth-child(3n+2) .technology-list__item-img::before {
  border-top-color: var(--header-black) !important;
}

section.technology .technology-list__item:nth-child(3n+3) .technology-list__item-img::before {
  border-top-color: #EAEAEA !important;
}

section.technology .technology-list__item-img {
  width: 50%;
  position: relative;
}

section.technology .technology-list__item-img::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  border-right: 300px solid transparent;
  border-top: 300px solid var(--accent-yellow);
  z-index: -1;
}

section.technology .technology-list__item-img img {
  max-height: 340px;
}

section.technology .technology-list__item-desc {
  width: 50%;
  font-size: 16px;
  line-height: 130%;
}

section.table {
  padding-top: 0;
  overflow-x: auto;
}

section.table .table-wrapper {
  min-width: 1600px;
  border-radius: 2px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}

section.table table {
  width: 100%;
  font-size: 18px;
}

section.table table thead {
  color: var(--accent-yellow);
  background: var(--header-black);
}

section.table table thead th {
  padding: 20px 18px;
}

section.table table tbody tr:last-child td {
  border: none;
}

section.table table tbody td {
  text-align: center;
  border-bottom: 2px solid #BFBFBF;
}

section.table table tbody td:first-child {
  padding: 20px 18px;
}

section.table table tbody td:nth-child(even) {
  background: #F2F2F2;
}

section.table table tbody td p {
  padding: 5px 0;
  border-bottom: 1px solid #BFBFBF;
}

section.table table tbody td p:last-child {
  border: none;
}

section.table .table-wrapper {
  display: flex;
  width: 100%;
}

section.table .table-wrapper ul {
  flex-grow: 1;
  display: flex;
}

section.table .table-wrapper ul:first-child {
  color: var(--accent-yellow);
  background: var(--header-black);
}

section.table .table-wrapper ul:not(:first-child):nth-child(even) {
  background: #F2F2F2;
}

section.table .table-wrapper ul li {
  flex-grow: 1;
  padding: 20px 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #BFBFBF;
}

@media (max-width: 1620px) {
  .container {
    max-width: 1400px;
  }

  header .logo {
    width: 260px;
  }

  .contacts .tel-soc,
  .contacts-mob .tel-soc {
    margin-right: 32px;
  }

  section.hose-sys .yellow-lines img {
    max-width: 70%;
  }

  section.counts .counts__list {
    width: 84%;
  }
}

@media (max-width: 1420px) {
  .container {
    max-width: 1200px;
  }

  .yellow-check li {
    font-size: 16px;
  }

  .h1-title h1 {
    font-size: 36px;
  }

  .fixed-btn {
    width: 130px;
    height: 130px;
    bottom: 5%;
    right: 3%;
  }

  .yellow-lines {
    padding: 50px 0;
  }

  header .logo {
    width: 200px;
  }

  header .navbar > ul li a {
    padding: 25px 14px;
  }

  .contacts .tel-soc,
  .contacts-mob .tel-soc {
    display: none;
  }

  section.hero .hero__list-item {
    height: 600px;
  }

  section.hero .hero__list-item h1,
  section.hero .hero__list-item .h1-cont {
    font-size: 58px;
  }

  section.hero .hero__list-item .h1-cont {
    font-size: 48px;
  }

  section.quality .quality__list .quality__list-item .quality__item-img {
    width: 150px;
    height: 150px;
    margin-bottom: 14px;
  }

  section.quality .quality__list .quality__list-item .quality__item-text {
    font-size: 14px;
  }

  section.constructor .form-container .pressure-input::after,
  section.constructor .form-container .input-div::after {
    font-size: 14px;
  }

  section.constructor .form-container .form-item {
    font-size: 14px;
  }

  section.constructor .form-container .form-item input {
    font-size: 14px;
    padding: 0 15px;
  }

  section.constructor .form-container .reset {
    font-size: 14px;
  }

  section.constructor .form-container .jq-selectbox .jq-selectbox__select {
    padding: 0 15px;
  }

  section.constructor .form-container .jq-selectbox .jq-selectbox__select-text {
    font-size: 14px;
  }

  section.constructor .form-container .jq-selectbox .jq-selectbox__dropdown ul {
    padding: 10px;
  }

  section.constructor .form-container .jq-selectbox .jq-selectbox__dropdown li {
    font-size: 14px;
  }

  section.constructor .form-previews__item {
    font-size: 14px;
    height: 264px;
  }

  section.constructor .order .free-consultation {
    font-size: 14px;
  }

  section.promline-standards .promline-standards__tabs ul.tabs-title__list li {
    font-size: 16px;
    padding: 15px 50px 15px 45px;
  }

  section.promline-standards .promline-standards__tabs .tabs-content__list-item .tabs-content__item-content {
    flex-direction: column-reverse;
  }

  section.promline-standards .promline-standards__tabs .tabs-content__list-item .tabs-content__item-desc {
    font-size: 16px;
  }

  section.promline-standards .promline-standards__tabs .tabs-content__list-item .tabs-content__item-img {
    min-width: 100%;
    height: 300px;
    margin-left: 0;
    margin-bottom: 30px;
  }

  section.promline-standards .promline-standards__tabs .tabs-content__list-item .tabs-content__item-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  section.hero-branch {
    padding: 50px 0;
  }

  section.hero-branch .yellow-list {
    margin-bottom: 70px;
  }

  section.hero-branch .yellow-list__item {
    font-size: 16px;
  }

  section.hero-branch .hero-branch__list-item {
    min-height: 150px;
    height: 220px;
  }

  footer {
    padding: 50px 0 40px;
  }

  .error-404-img {
    max-width: 580px;
  }

  section.contacts {
    padding: 50px 0;
  }

  section.contacts .constacts-top .constacts-socials {
    padding: 30px 20px;
  }

  section.contacts .constacts-top .contacts-info {
    padding: 30px 20px;
  }

  section.contacts .contacts-form {
    margin-top: 100px;
  }

  section.products .products-list .products-list__item {
    width: 42%;
    margin-bottom: 70px;
    padding: 25px;
    min-height: 300px;
  }

  section.products .products-list .products-list__item::before {
    bottom: 25px;
    left: 25px;
    border-right: 260px solid transparent;
    border-bottom: 260px solid #E23A63;
  }

  section.products .products-list .products-list__item-title {
    font-size: 18px;
  }

  section.products .products-list .products-list__item-img {
    height: auto;
    width: 180px;
  }

  section.products .products-list .products-list__item-more {
    padding: 15px;
  }

  section.products .products-list .products-list__item-more ul li a {
    font-size: 16px;
    padding: 14px 12px;
  }

  section.products .products-list .products-list__item-more ul li .img {
    max-width: 160px;
    height: 140px;
    right: 25px;
    top: calc(50% - 70px);
  }

  section.single-product .single-product__info .single-product__info-left .single-product__images .images-item {
    height: 400px;
  }

  section.single-product .single-product__info .single-product__info-left .single-product__images .images-small-item {
    height: 100px;
  }

  section.single-product .single-product__info .single-product__info-right h2 {
    font-size: 36px;
  }

  section.single-product .single-product__info .single-product__info-right p {
    font-size: 22px;
  }

  section.table table {
    font-size: 16px;
  }

  section.table table thead th {
    padding: 10px 9px;
  }

  section.table table tbody td:first-child {
    padding: 10px 9px;
  }
}

@media (max-width: 1415px) {
  .materials-promo__inner {
    height: 40.625rem;
  }

  .prompt-line__wrapper1 {
    width: 43.4375rem;
    right: calc(-100% - 25.625rem);
  }

  .prompt-line__wrapper2 {
    width: 34.6875rem;
    right: calc(-100% - 17.75rem);
  }
}

@media (max-width: 1220px) {
  .container {
    max-width: 992px;
  }

  section.quality .quality__list .quality__list-item {
    width: 30%;
    margin-bottom: 44px;
  }

  section.hero-branch .hero-branch__list-item .hero-branch__item-desc {
    font-size: 14px;
  }

  section.contacts h4 {
    font-size: 20px;
  }
}

@media (max-width: 1185px) {
  .prompt-line__wrapper1 {
    width: 41.4375rem;
    right: calc(-100% - 19.625rem);
    top: 110px;
  }

  .prompt-line__wrapper2 {
    width: 31.6875rem;
    right: calc(-100% - 10.75rem);
  }
}

@media (max-width: 1040px) {
  .materials__promo__section {
    display: flex;
    flex-direction: column;
  }

  .materials-promo__container {
    order: 3;
  }

  .materials-promo__inner {
    height: unset;
    position: initial;
  }

  .materials-promo-text__item {
    position: initial;
    max-width: 100%;
    text-align: left;
    margin-bottom: 25px;
  }

  .prompt-line__wrapper {
    display: none;
  }

  .materials__promo__section .container__large {
    order: 2;
  }

  .cable-img {
    margin-top: 0;
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
  }
}

@media (max-width: 1000px) {
  .container {
    max-width: 90%;
  }

  header .navbar-wrapper {
    transition: all .3s ease;
    position: fixed;
    visibility: hidden;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    right: 0;
    top: 0;
  }

  header .navbar-wrapper.active {
    opacity: 1;
    visibility: visible;
  }

  header .navbar-wrapper.active .navbar {
    right: 0;
  }

  header .navbar-wrapper .navbar {
    right: -320px;
    width: 320px;
    height: 100%;
    position: fixed;
    right: -320px;
    top: 0;
    background: var(--header-black);
    padding: 90px 0 20px;
  }

  header .navbar > ul {
    height: auto;
    width: 100%;
    background: var(--header-black);
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  header .navbar > ul li {
    width: 100%;
  }

  header .navbar > ul li:hover > ul {
    top: 0;
    padding: 10px 15px;
    max-height: 700px;
  }

  header .navbar > ul li a {
    padding: 15px 20px;
  }

  header .navbar > ul li > ul {
    padding: 0;
    max-height: 0;
    overflow: hidden;
    position: relative;
    left: 0;
    top: 0;
  }

  header .burger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    order: 1;
  }

  header .burger-close {
    display: block;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 30px;
  }

  header .burger-close::before,
  header .burger-close::after {
    content: '';
    display: block;
    position: absolute;
    position: relative;
    width: 100%;
    height: 2px;
    background: #fff;
    position: absolute;
  }

  header .burger-close::before {
    transform: rotate(45deg);
  }

  header .burger-close::after {
    transform: rotate(-45deg);
  }

  section.counts .counts__list {
    width: 90%;
    margin: 0 auto;
    padding: 25px 10px;
  }

  section.solution .solution__list {
    flex-direction: column;
  }

  section.solution .solution__list-item {
    height: auto;
    width: 100%;
    margin-bottom: 40px;
  }

  section.solution .solution__list-item:hover {
    width: 100%;
  }

  section.solution .solution__list-item:hover .top-el__title {
    position: relative;
    text-align: center;
    color: var(--accent-yellow);
    left: 0;
    top: 0;
  }

  section.solution .top-el {
    height: 216px;
  }

  section.solution .bottom-el {
    padding: 0;
    position: relative;
    left: 0;
  }

  section.solution .bottom-el::before {
    display: none;
  }

  section.solution .bottom-el ul li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--text-color);
    text-decoration: underline;
  }

  section.solution .bottom-el ul li a::after {
    border-color: var(--accent-yellow);
  }

  section.constructor .form-container .form-item {
    width: 48%;
    font-size: 16px;
    margin-bottom: 30px;
  }

  section.constructor .form-container .form-item input {
    font-size: 16px;
  }

  section.constructor .form-container .reset {
    font-size: 16px;
  }

  section.constructor .form-container .reset {
    font-size: 16px;
    margin-top: 0;
  }

  section.constructor .form-container .jq-selectbox .jq-selectbox__select-text {
    font-size: 16px;
  }

  section.constructor .form-container .jq-selectbox .jq-selectbox__dropdown li {
    font-size: 16px;
  }

  section.constructor .form-previews__item {
    font-size: 16px;
  }

  section.constructor .order .free-consultation {
    font-size: 16px;
  }

  section.promline-standards {
    padding: 50px 0 30px;
    margin-bottom: 60px;
  }

  section.promline-standards .promline-standards__title {
    font-size: 28px;
  }

  section.promline-standards .promline-standards__tabs {
    margin-top: 30px;
    flex-direction: column;
  }

  section.promline-standards .promline-standards__tabs ul.tabs-title__list {
    width: 100%;
  }

  section.promline-standards .promline-standards__tabs .tabs-content__list {
    width: 100%;
  }

  section.promline-standards .promline-standards__tabs .tabs-content__list-item {
    width: 90%;
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }

  section.promline-standards .promline-standards__tabs .tabs-content__list-item.active {
    left: 48px;
    padding-bottom: 21px;
    border-bottom: 1px solid #444;
    opacity: 1;
    visibility: visible;
  }

  section.promline-standards .promline-standards__tabs .tabs-content__list-item .tabs-content__item-title {
    font-size: 24px;
  }

  section.hero-branch .hero-branch__list-item {
    width: 50%;
    margin-bottom: 34px;
  }

  section.hero-branch .hero-branch__list-item:nth-child(4),
  section.hero-branch .hero-branch__list-item:nth-child(5),
  section.hero-branch .hero-branch__list-item:nth-child(6),
  section.hero-branch .hero-branch__list-item:nth-child(10),
  section.hero-branch .hero-branch__list-item:nth-child(11),
  section.hero-branch .hero-branch__list-item:nth-child(12) {
    flex-direction: row;
    text-align: left;
  }

  section.contacts .constacts-top .constacts-socials {
    width: 100%;
    order: 1;
    margin-top: 60px;
  }

  section.contacts .constacts-top .contacts-info {
    width: 100%;
    padding: 30px 10px 0;
  }

  section.products .products-list .products-list__item {
    padding: 15px;
  }

  section.products .products-list .products-list__item::before {
    bottom: 15px;
    left: 15px;
  }

  section.products .products-list .products-list__item-img {
    width: 140px;
  }

  section.products .products-list .products-list__item-more ul li a {
    padding: 10px 12px;
  }

  section.products .products-list .products-list__item-more ul li .img {
    display: none;
  }

  section.single-product .single-product__info .single-product__info-left {
    width: 100%;
  }

  section.single-product .single-product__info .single-product__info-left .single-product__images .images-small {
    margin-bottom: 40px;
  }

  section.single-product .single-product__info .single-product__info-right {
    width: 100%;
  }

  section.single-product .single-product__info .single-product__info-right h2 {
    font-size: 36px;
    display: none;
  }

  section.single-product h2.mob {
    display: block;
    font-size: 20px;
    line-height: 130%;
  }

  section.technology .technology-list__item-img {
    width: 100%;
    margin-bottom: 40px;
  }

  section.technology .technology-list__item-desc {
    width: 100%;
  }
}

@media (max-width: 991px) {
  header {
    padding: 10px 0;
  }

  header .navbar > ul li > ul li:hover ul {
    max-height: 1000px;
  }

  header .navbar > ul li > ul li ul {
    position: relative;
    top: 0;
    left: 0 !important;
    max-height: 0px;
    overflow: hidden;
  }

  header .languages__mob {
    display: flex;
    justify-content: center;
  }

  header .contacts-mob {
    display: block;
  }

  header .contacts-mob .tel-soc {
    display: block;
    padding: 0 15px;
    margin-top: 25px;
    text-align: center;
  }

  .contacts .languages,
  .contacts-mob .languages {
    display: none;
  }
}

@media (max-width: 990px) {
  .materials-promo__container {
    z-index: -1;
  }

  .materials__promo__section .section__heading {
    text-align: center;
  }
}

@media (max-width: 800px) {
  section.counts .counts__list-item .count {
    font-size: 48px;
  }

  section.counts .counts__list-item .text {
    font-size: 8px;
  }
}

@media (max-width: 767px) {
  section {
    padding: 50px 0;
  }

  h2 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 30px;
  }

  .desc {
    font-size: 16px;
  }

  .yellow-block {
    width: 100%;
    font-size: 18px;
    padding: 5px 15px;
  }

  .yellow-check li {
    font-size: 12px;
  }

  .h1-title h1 {
    font-size: 26px;
    padding: 0;
  }

  .h1-title h1::before,
  .h1-title h1::after {
    display: none;
  }

  .h1-title p {
    font-size: 14px;
  }

  .breadcrumbs {
    font-size: 12px;
  }

  .yellow-lines::before {
    display: none;
  }

  .hose-block {
    display: none;
  }

  .hose-mobile {
    display: block;
  }

  header .logo {
    width: 160px;
  }

  section.hero .hero__list .paginations {
    width: 100%;
    height: 70px;
  }

  section.hero .hero__list-item {
    height: 85vh;
    padding-bottom: 70px;
  }

  section.hero .hero__list-item h1,
  section.hero .hero__list-item .h1-cont {
    font-size: 32px;
  }

  section.hero .hero__list-item .h1-cont {
    font-size: 24px;
    margin-top: -10px;
  }

  section.hose-sys h2 {
    display: none;
  }

  section.hose-sys h2.mob {
    display: block;
  }

  section.hose-sys .hose-block {
    display: none;
  }

  section.hose-sys .yellow-block {
    font-size: 18px;
  }

  section.quality .quality__list .quality__list-item {
    width: 48%;
  }

  section.our-partners .our-partners__list-item {
    animation-name: partnersCarousel-1000;
    margin-bottom: 24px;
    margin-right: 24px;
    min-width: 30%;
    height: 180px;
  }

  section.constructor .form-container .form-item {
    width: 100%;
  }

  section.constructor .form-container .form-item .jq-selectbox__dropdown li::before,
  section.constructor .form-container .form-item .jq-selectbox__dropdown li::after {
    display: none !important;
  }

  section.constructor .form-container .form-item.disabled-sel .jq-selectbox .alert-message,
  section.constructor .form-container .form-item.disabled-sel .input-div .alert-message {
    right: 0;
  }

  section.constructor .form-container .form-item__title .help-text {
    left: 0;
  }

  section.constructor .form-container .jq-selectbox .jq-selectbox__dropdown {
    max-width: 100%;
  }

  section.constructor .form-previews__item {
    font-size: 10px;
    width: 32%;
    height: 100px;
  }

  section.constructor .order .free-consultation {
    margin-top: 12px;
    margin-right: 0;
    width: 100%;
    font-size: 14px;
    text-align: center;
    line-height: 120%;
    order: 1;
  }

  section.constructor .order .yellow-btn {
    margin: 0 auto;
  }

  section.promline-standards .promline-standards__tabs ul.tabs-title__list li {
    font-size: 10px;
    padding-left: 35px;
  }

  section.promline-standards .promline-standards__tabs ul.tabs-title__list li::after {
    width: 6px;
    height: 6px;
    top: calc(50% - 3px);
  }

  section.promline-standards .promline-standards__tabs .tabs-content__list-item {
    padding-top: 0;
  }

  section.promline-standards .promline-standards__tabs .tabs-content__list-item .tabs-content__item-title {
    font-size: 20px;
  }

  section.promline-standards .promline-standards__tabs .tabs-content__list-item .tabs-content__item-desc {
    font-size: 14px;
  }

  section.hero-branch {
    padding-top: 28px;
  }

  section.hero-branch::before {
    display: none;
  }

  section.hero-branch .yellow-list__item {
    width: 100%;
    margin-bottom: 14px;
  }

  section.hero-branch .hero-branch__list-item {
    width: 100%;
    min-height: 150px;
    font-size: 12px;
  }

  section.hero-branch .hero-branch__list-item:nth-child(even) {
    flex-direction: row-reverse;
    text-align: right;
  }

  section.hero-branch .hero-branch__list-item:nth-child(even) .hero-branch__item-desc {
    text-align: right;
  }

  section.hero-branch .hero-branch__list-item .hero-branch__item-desc {
    padding: 0 8px;
    font-size: 12px;
  }

  footer .wrapper {
    display: none;
    flex-direction: column;
    align-items: center;
  }

  footer ul {
    width: 100%;
  }

  footer .footer-logo {
    margin-top: 60px;
  }

  .error-404-img {
    margin-bottom: 50px;
  }

  .error-404-title {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .error-404-desc {
    font-size: 14px;
  }

  .error-404-btn {
    width: 100%;
  }

  section.contacts h4 {
    font-size: 16px;
  }

  section.contacts .constacts-top .contacts-info__item {
    width: 100%;
    margin-bottom: 32px;
  }

  section.contacts .contacts-form {
    padding: 60px 10px 20px;
    margin-top: 50px;
  }

  section.contacts .contacts-form h2 {
    font-size: 16px;
    text-align: left;
  }

  section.contacts .contacts-form p {
    font-size: 14px;
    text-align: left;
  }

  section.contacts .contacts-form form label,
  section.contacts .contacts-form form button {
    width: 100%;
    margin-bottom: 20px;
  }

  section.contacts .contacts-form form label:nth-child(2) {
    margin-bottom: 48px;
  }

  section.products .h1-title {
    margin-top: 60px;
    margin-bottom: 30px;
  }

  section.products .products-list .products-list__item {
    margin: 0 auto 20px;
    width: 300px;
    min-height: 220px;
  }

  section.products .products-list .products-list__item::before {
    border-right: 180px solid transparent;
    border-bottom: 180px solid #E23A63;
  }

  section.products .products-list .products-list__item-img {
    width: 100px;
    height: auto;
    transform: translateX(-50px);
  }

  section.products .products-list .products-list__item-btn {
    width: 100px;
    height: 30px;
    font-size: 12px;
  }

  section.products .products-list .products-list__item-more ul {
    margin-top: 10px;
  }

  section.products .products-list .products-list__item-more ul li a {
    font-size: 12px;
  }

  section.single-product .single-product__info .single-product__info-left .single-product__images .images-item {
    height: 290px;
  }

  section.single-product .single-product__info .single-product__info-left .single-product__images .images-small-item {
    height: 72px;
  }

  section.single-product .single-product__info .single-product__info-right p {
    font-size: 16px;
  }

  section.technology .technology-list__item:nth-child(even) .technology-list__item-img::before {
    border-left: 200px solid transparent;
    border-top: 200px solid var(--accent-yellow);
  }

  section.technology .technology-list__item-img::before {
    border-right: 200px solid transparent;
    border-top: 200px solid var(--accent-yellow);
  }
}

@media (max-width: 535px) {
  .materials__promo__section .section__heading {
    font-size: 28px;
  }

  .materials__promo__section .container__large {
    padding-left: 15px;
  }

  .cable-img {
    display: none;
  }

  .cable-img-mobile {
    display: block;
  }
}

@media (max-width: 340px) {
  section.hero .hero__list-item .h1-cont {
    font-size: 22px;
  }

  section.quality .quality__list .quality__list-item .quality__item-img {
    width: 100px;
    height: 100px;
    margin-bottom: 14px;
  }

  section.quality .quality__list .quality__list-item .quality__item-text {
    font-size: 10px;
  }

  section.solution .bottom-el ul li a {
    font-size: 16px;
  }

  section.our-partners .our-partners__list-container {
    max-height: 210px;
  }

  section.our-partners .our-partners__list-item {
    animation-name: partnersCarousel-276;
    animation-duration: 12s;
    margin-bottom: 12px;
    margin-right: 12px;
    height: 90px;
  }

  section.our-partners .our-partners__list-item img {
    height: 100%;
  }

  section.hero-branch .hero-branch__list-item {
    height: 140px;
  }
}