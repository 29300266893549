section.solution {
  padding-top: 0;

  .solution__list {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1000px) {
      flex-direction: column; } }

  .solution__list-item {
    width: 33.33%;
    height: 360px;
    position: relative;
    overflow: hidden;
    transition: all .3s ease;
    @media (max-width: 1000px) {
      height: auto;
      width: 100%;
      margin-bottom: 40px;
      &:hover {
        width: 100%;
        .top-el__title {
          position: relative;
          text-align: center;
          color: var(--accent-yellow);
          left: 0;
          top: 0; } } }

    &:hover {
      width: 70%;
      .top-el__title {
        color: #fff;
        text-align: left;
        position: absolute;
        left: 35px;
        top: 50px; }
      .bottom-el {
        left: 0; } }

    &:nth-child(1) {
      .bottom-el::before {
        background: #2970C2; } }
    &:nth-child(2) {
      .bottom-el::before {
        background: #E23A63; } }
    &:nth-child(3) {
      .bottom-el::before {
        background: #428941; } } }

  .top-el {
    padding: 0 15px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width: 1000px) {
      height: 216px; }

    &__title {
      color: var(--accent-yellow);
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      transition: all .3s ease; }
    &__img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover; } } }

  .bottom-el {
    margin-top: 24px;
    padding: 140px 22px 30px;
    width: 100%;
    height: 100%;
    padding-top: 140px;
    position: absolute;
    left: -900px;
    top: 0;
    transition: all .3s ease;
    @media (max-width: 1000px) {
      padding: 0;
      position: relative;
      left: 0;
      &::before {
        display: none; } }

    &::before {
      @include ba();
      width: 1100px;
      height: 900px;
      top: -80px;
      left: -390px;
      transform: rotate(45deg);
      z-index: -1;
      transition: all .3s ease; }

    ul {
      li {
        a {
          display: inline-block;
          padding: 8px 20px 8px 12px;
          color: #fff;
          font-weight: 600;
          font-size: 20px;
          line-height: 130%;
          letter-spacing: 0.01em;
          transition: var(--trans-2s);
          @media (max-width: 1000px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--text-color);
            text-decoration: underline;
            &::after {
              border-color: var(--accent-yellow); } }
          @media (max-width: 340px) {
            font-size: 16px; }

          &:hover {
            color: var(--accent-yellow);
            background: var(--header-black);
            &::after {
              border-color: var(--accent-yellow); } }

          &::after {
            @include ba();
            position: relative;
            display: inline-block;
            margin-left: 5px;
            width: 8px;
            height: 8px;
            transform: rotate(-45deg);
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff; } } } } } }
