section.hero {
  padding: 0;
  .hero__list {
    z-index: 1;
    position: relative;

    .paginations {
      width: 580px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: var(--accent-yellow);
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      @media (max-width: 767px) {
        width: 100%;
        height: 70px; }

      .swiper-pagination {
        width: 75%;
        margin: 0 auto; }
      .swiper-pagination-bullet {
        width: 15px;
        height: 15px; }
      .swiper-pagination-bullet-active {
        background: var(--text-color); }

      .swiper-button-prev,
      .swiper-pagination,
      .swiper-button-next {
        position: relative;
        bottom: 0;
        top: 0; }

      .swiper-button-prev,
      .swiper-button-next {
        margin-bottom: -20px;
        &::before {
          @include ba();
          width: 21px;
          height: 21px;
          border-left: 3px solid var(--text-color);
          border-bottom: 3px solid var(--text-color);
          border-radius: 2px;
          transform: rotate(45deg); }
        &::after {
          border-radius: 2px;
          font-size: 0;
          width: 36px;
          height: 3px;
          background: var(--text-color); } }

      .swiper-button-next {
        transform: rotate(180deg); } }

    &-item {
      position: relative;
      width: 100%;
      height: 725px;
      @media (max-width: 1420px) {
        height: 600px; }
      @media (max-width: 767px) {
        // height: 395px
        height: 85vh;
        padding-bottom: 70px; }

      .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%; }

      h1, .h1-cont {
        z-index: 2;
        color: #2d2d2d;
        background: var(--accent-yellow);
        display: inline-block;
        font-size: 76px;
        padding: 10px 25px;
        font-weight: 700;
        @media (max-width: 1420px) {
          font-size: 58px; }
        @media (max-width: 767px) {
          font-size: 32px; } }

      .h1-cont {
        font-size: 66px;
        padding: 10px 25px;
        margin-top: -30px;
        @media (max-width: 1420px) {
          font-size: 48px; }
        @media (max-width: 767px) {
          font-size: 24px;
          margin-top: -10px; }
        @media (max-width: 340px) {
          font-size: 22px; } }

      .hero-img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover; } } } } }
