@mixin font($font_name, $file_name, $weight, $style) {
  @font-face {
    font-family: $font_name;
    src: url("../fonts/#{$file_name}.woff") format("woff"), url("../fonts/#{$file_name}.woff2") format("woff2");
    font-weight: #{$weight};
    font-style: #{$style}; } }

@include font(Mont, Mont-Black, 800, black);
@include font(Mont, Mont-Bold, 700, bold);
@include font(Mont, Mont-Regular, 400, normal);
@include font(Mont, Mont-Light, 300, lighter);
