section.promline-standards {
  padding: 80px 0 100px;
  margin-bottom: 150px;
  color: #fff;
  background: center / cover no-repeat url('../img/tabs-bg.jpg');
  @media (max-width: 1000px) {
    padding: 50px 0 30px;
    margin-bottom: 60px; }

  .promline-standards__title {
    font-size: 35px;
    font-weight: 700;
    line-height: 130%;
    text-transform: uppercase;
    @media (max-width: 1000px) {
      font-size: 28px; } }

  .promline-standards__tabs {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 1000px) {
      margin-top: 30px;
      flex-direction: column; }

    ul.tabs-title__list {
      width: 40%;
      counter-reset: tabs-list;
      @media (max-width: 1000px) {
        width: 100%; }
      li {
        counter-increment: tabs-list;
        margin-bottom: 10px;
        padding: 20px 50px 20px 50px;
        font-size: 18px;
        font-weight: 600;
        line-height: 130%;
        cursor: pointer;
        transition: var(--trans-2s);
        border-radius: 2px;
        position: relative;
        &:hover {
          color: var(--accent-yellow);
          background-color: #3A3A3A;
          &::after {
            border-color: var(--accent-yellow); } }

        @media (max-width: 1420px) {
          font-size: 16px;
          padding: 15px 50px 15px 45px; }
        @media (max-width: 767px) {
          font-size: 10px;
          padding-left: 35px; }

        &::before {
          margin-right: 10px;
          content: counter(tabs-list) ")";
          height: 22px;
          position: absolute;
          top: calc(50% - 11px);
          left: 18px;
          display: flex;
          align-items: center; }
        &::after {
          @include ba();
          right: 28px;
          top: calc(50% - 6px);
          transform: rotate(-45deg);
          width: 12px;
          height: 12px;
          transition: var(--trans-2s);
          border-right: 2px solid #fff;
          border-bottom: 2px solid #fff;
          @media (max-width: 767px) {
            width: 6px;
            height: 6px;
            top: calc(50% - 3px); } }

        &.active {
          color: var(--text-color);
          background: var(--accent-yellow);
          &::after {
            border-color: var(--text-color); } } } }

    .tabs-content__list {
      width: 55%;
      @media (max-width: 1000px) {
        width: 100%; }

      &-item {
        @media (max-width: 1000px) {
          width: 90%;
          opacity: 0;
          visibility: hidden;
          position: absolute;
          &.active {
            left: 48px;
            padding-bottom: 21px;
            border-bottom: 1px solid #444;
            opacity: 1;
            visibility: visible; } }

        @media (max-width: 767px) {
          padding-top: 0; }

        .tabs-content__item-title {
          margin-bottom: 30px;
          font-weight: 700;
          font-size: 30px;
          line-height: 130%;
          @media (max-width: 1000px) {
            font-size: 24px; }
          @media (max-width: 767px) {
            font-size: 20px; } }

        .tabs-content__item-content {
          margin-bottom: 34px;
          display: flex;
          justify-content: space-between;
          @media (max-width: 1420px) {
            flex-direction: column-reverse; } }

        .tabs-content__item-desc {
          color: #CECECE;
          font-size: 18px;
          line-height: 130%;
          @media (max-width: 1420px) {
            font-size: 16px; }
          @media (max-width: 767px) {
            font-size: 14px; } }

        .tabs-content__item-img {
          margin-left: 25px;
          min-width: 45%;
          overflow: hidden;
          border-radius: 5px;
          @media (max-width: 1420px) {
            min-width: 100%;
            height: 300px;
            margin-left: 0;
            margin-bottom: 30px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover; } } } } } } }
